export default [{
  key: 'competencia', label: 'Data',
},
{
  key: 'situacao', label: 'Ação',
},
{
  key: 'criadoPor', label: 'Usuário',
},
]
